import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FunctionalButton } from 'components/functionalButton/index.js';

import {
  ImagePopup,
  LargeText,
  SmallText,
  ButtonArea,
  PopupContainer,
} from 'pages/contractorDashboard/profileSetup/styles.js';
import { Popup } from 'components/popup/index.js';
import Success from "assets/dashboardIcons/Success";
import { CloseButton } from "components/closeButton";

export const SuccessPopup = ({
  openPopup,
  setOpenPopup,
  buttonText='DONE',
  descriptionText='The PGA and Scores are ready now. 👌',
  titleText='Success!',
  isMobile
}) => {

  const handleDone = () => {
    setOpenPopup(false);
  };

  return (
    <Popup
        open={openPopup}
        setOpen={setOpenPopup}
        width={isMobile ? "100%" : "550px"}
        height={isMobile ? "390px" : "480px"}
        dialogContent={(
          <>
            <PopupContainer>
              <CloseButton handleClose={handleDone} />
              <ImagePopup>
                <Success isMobile={isMobile} />
              </ImagePopup>
              <LargeText isMobile={isMobile}>{ titleText }</LargeText>
              <SmallText>
                { descriptionText }
              </SmallText>
            </PopupContainer>
            <ButtonArea isMobile={isMobile}>
              <FunctionalButton
                buttonTitle={ buttonText }
                width="90%"
                height={isMobile ? "48px" : "53px"}
                marginRight="0px"
                handleButton={handleDone}
              />
            </ButtonArea>
        </>
        )}
      />

  )
}
