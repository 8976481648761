import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { StyledContainer, StyledRadioLabel } from "./styles";

import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

import { AllClientHeader } from "./components/AllClientHeader";
import { AllClientsTable } from "./components/allClientsTable";
import { getUnderwriterClientInfo } from "../../data/actions/underwriters";
import { underwriterClientSelector } from "data/selectors/underwriters";
import { GoBackButton } from "pages/buyers/components/goBackButton";
import { AddAccountPopup } from "../../components/addAccountPopup/addAccountPopup";

const AllClientPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 453 });
  let history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const underwriterClientData = useSelector(underwriterClientSelector);
  const [query, setQuery] = useState("");
  const [internalSearchResults, setInternalSearchResults] = useState(data);
  const [showAddAccountDialog, setShowAddAccountDialog] = useState(false);

  useEffect(() => {
    if (underwriterClientData.length < 1) {
      dispatch(getUnderwriterClientInfo());
    }
  }, []);
  useEffect(() => {
    if (underwriterClientData) {
      setData(underwriterClientData);
    }
  }, [underwriterClientData]);

  return (
    <>
      <AddAccountPopup
        open={showAddAccountDialog}
        setOpen={setShowAddAccountDialog}
      />
      <StyledContainer isMobile={isMobile}>
        <GoBackButton />
        <AllClientHeader
          title="All Accounts"
          hasButton={true}
          data={data}
          setData={setData}
          query={query}
          setQuery={setQuery}
          internalSearchResults={internalSearchResults}
          setInternalSearchResults={setInternalSearchResults}
          handleButton={() => setShowAddAccountDialog(true)}
        />
        {data && (
          <AllClientsTable
            data={query && query !== "" ? internalSearchResults : data}
            setData={setData}
          />
        )}
      </StyledContainer>
    </>
  );
};

export default AllClientPage;
