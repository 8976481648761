import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../components/spinner";

import {
  ButtonPlacement,
  Content,
  MediumText,
  PlaceholderText,
  StyledArea,
  StyledBox,
  StyledTooltipButton,
} from "../styles";

import { ListContainer } from "./styles";

import { useHistory } from "react-router-dom";
import { FunctionalButton } from "../../../components/functionalButton";
import { ArrowIcon } from "./asset/ArrowIcon";
import { AllClientsTable } from "pages/underwriters/components/allClientsTable";
import { underwriterClientSelector } from "data/selectors/underwriters";
import { getUnderwriterClientInfo } from "data/actions/underwriters";
import { AddAccountPopup } from "../../../components/addAccountPopup/addAccountPopup";

export const ClientSection = ({ isMobile, userData }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: false,
  });
  const [data, setData] = useState([]);
  const underwriterClientData = useSelector(underwriterClientSelector);

  const [showAddAccountDialog, setShowAddAccountDialog] = useState(false);

  useEffect(() => {
    dispatch(getUnderwriterClientInfo());
  }, []);

  useEffect(() => {
    if (underwriterClientData) {
      setData(underwriterClientData);
    }
  }, [underwriterClientData]);

  const handleSeeAll = () => {
    history.push("/underwriter/clients");
  };

  // const handleNew = () => {
  //   history.push("/rfpq");
  // };

  return (
    <>
      <AddAccountPopup
        open={showAddAccountDialog}
        setOpen={setShowAddAccountDialog}
      />
      <Content isMobile={isMobile}>
        <MediumText isMobile={isMobile} style={{ marginBottom: "40px" }}>
          Recently Added Accounts
        </MediumText>
        <StyledBox
          hasData={data?.length > 0}
          noBackground={data?.length > 0 ? true : false}
          boxHeight="270px"
        >
          {!state.loading && data?.length > 0 && (
            <ListContainer>
              <AllClientsTable
                data={data?.slice(0, 3)}
                setData={setData}
                isMobile={isMobile}
                borderRadius="20px"
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px;"
              />
            </ListContainer>
          )}
          {!state.loading && !(data?.length > 0) && (
            <PlaceholderText>Your clients will appear here</PlaceholderText>
          )}
          {state.loading && (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spinner />
              <span style={{ marginTop: "20px" }}>Loading Clients...</span>
            </div>
          )}
        </StyledBox>
        <StyledArea
          marginTop={isMobile && data?.length > 0 ? "-38px" : "12px"}
          isMobile={isMobile}
        >
          <ButtonPlacement isMobile={isMobile}>
            {2 > 0 && (
              <FunctionalButton
                buttonTitle={isMobile ? "See All" : "See All Accounts"}
                handleButton={handleSeeAll}
                width="500px"
                marginRight="0px"
                isReversedColor={true}
                icon={<ArrowIcon fill="#FF6D1D" />}
                hasBoxShadow={true}
                disabled={state.loading}
              />
            )}
          </ButtonPlacement>
          {userData?.status === "active" && (
            <StyledTooltipButton>
              <FunctionalButton
                buttonTitle={isMobile ? "Add New" : "Add a New Account"}
                // handleButton={
                //   userData?.status === "active" ? handleNew : () => {}
                // }
                width="500px"
                marginRight="13px"
                icon={<ArrowIcon fill="white" />}
                hasBoxShadow={true}
                handleButton={() => setShowAddAccountDialog(true)}
              />
            </StyledTooltipButton>
          )}
        </StyledArea>
      </Content>
    </>
  );
};
