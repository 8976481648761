import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  GreySmallText,
  PlaceholderText,
  SmallText,
  StyledBox,
} from "../styles";
import { SeeAllText, TitleDiv } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { underwriterIdpActiveDocumentsSelector } from "../../../data/selectors/underwriters";
import { getUnderwriterIdpActiveDocuments } from "../../../data/actions/underwriters";
import { truncateString } from "../../../common/truncateString";

export const TabPanel1 = ({ isMobile }) => {
  const [documentList, setDocumentList] = useState("");
  const history = useHistory();

  const dispatch = useDispatch();

  const underwriterIdpActiveDocumentsData = useSelector(
    underwriterIdpActiveDocumentsSelector,
    (prev, next) => prev.length === next.length,
  );

  useEffect(() => {
    if (underwriterIdpActiveDocumentsData.length < 1) {
      dispatch(getUnderwriterIdpActiveDocuments());
    }

    if (underwriterIdpActiveDocumentsData) {
      console.log(
        "idp documents length: ",
        underwriterIdpActiveDocumentsData.length,
      );
      setDocumentList(underwriterIdpActiveDocumentsData);
    }
  }, [underwriterIdpActiveDocumentsData]);

  const handleSeeIdpDocuments = () => {
    history.push("underwriter/idpdocuments");
  };

  return (
    <>
      {!(documentList?.length > 0) && (
        <StyledBox boxHeight="80px" borderRadius="10px">
          <PlaceholderText>
            Pending IDP documents will appear here
          </PlaceholderText>
        </StyledBox>
      )}
      {documentList?.length > 0 &&
        documentList
          .slice(-6)
          .reverse()
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .map((item, i) => {
            if (item.account_name) return <DocumentsCard {...item} key={i} />;
          })}
      {documentList?.length > 0 && (
        <SeeAllText onClick={handleSeeIdpDocuments}>
          See all IDP Documents
        </SeeAllText>
      )}
    </>
  );
};

const DocumentsCard = ({ account_name, idp_status }) => (
  <>
    <StyledBox boxHeight="80px" borderRadius="10px" margin="0 0 20px 0">
      <TitleDiv>
        <SmallText>{truncateString(account_name, 31)}</SmallText>
        <GreySmallText>{truncateString(idp_status, 31)}</GreySmallText>
      </TitleDiv>
    </StyledBox>
  </>
);
