export const naicsCodes: string[] = [
  "111110",
  "111120",
  "111130",
  "111140",
  "111150",
  "111160",
  "111190",
  "111211",
  "111219",
  "111310",
  "111320",
  "111330",
  "111411",
  "111412",
  "111419",
  "111421",
  "111422",
  "111910",
  "111920",
  "111930",
  "111940",
  "111993",
  "111994",
  "111995",
  "111999",
  "112110",
  "112120",
  "112210",
  "112310",
  "112320",
  "112330",
  "112340",
  "112391",
  "112399",
  "112410",
  "112420",
  "112510",
  "112910",
  "112920",
  "112930",
  "112991",
  "112999",
  "113110",
  "113210",
  "113311",
  "113312",
  "114113",
  "114114",
  "114210",
  "115110",
  "115210",
  "115310",
  "211110",
  "211141",
  "211142",
  "212114",
  "212115",
  "212116",
  "212210",
  "212220",
  "212231",
  "212232",
  "212233",
  "212291",
  "212299",
  "212314",
  "212315",
  "212316",
  "212317",
  "212323",
  "212326",
  "212392",
  "212393",
  "212394",
  "212395",
  "212396",
  "212397",
  "212398",
  "213111",
  "213117",
  "213118",
  "213119",
  "221111",
  "221112",
  "221113",
  "221119",
  "221121",
  "221122",
  "221210",
  "221310",
  "221320",
  "221330",
  "236110",
  "236210",
  "236220",
  "237110",
  "237120",
  "237130",
  "237210",
  "237310",
  "237990",
  "238110",
  "238120",
  "238130",
  "238140",
  "238150",
  "238160",
  "238170",
  "238190",
  "238210",
  "238220",
  "238291",
  "238299",
  "238310",
  "238320",
  "238330",
  "238340",
  "238350",
  "238390",
  "238910",
  "238990",
  "311111",
  "311119",
  "311211",
  "311214",
  "311221",
  "311224",
  "311225",
  "311230",
  "311310",
  "311340",
  "311351",
  "311352",
  "311410",
  "311420",
  "311511",
  "311515",
  "311520",
  "311611",
  "311614",
  "311615",
  "311710",
  "311811",
  "311814",
  "311821",
  "311824",
  "311830",
  "311911",
  "311919",
  "311920",
  "311930",
  "311940",
  "311990",
  "312110",
  "312120",
  "312130",
  "312140",
  "312210",
  "312220",
  "312310",
  "313110",
  "313210",
  "313220",
  "313230",
  "313240",
  "313310",
  "313320",
  "314110",
  "314120",
  "314910",
  "314990",
  "315110",
  "315190",
  "315210",
  "315220",
  "315241",
  "315249",
  "315281",
  "315289",
  "315990",
  "316110",
  "316210",
  "316990",
  "321111",
  "321112",
  "321114",
  "321211",
  "321212",
  "321215",
  "321216",
  "321217",
  "321911",
  "321919",
  "321920",
  "321991",
  "321992",
  "321999",
  "322111",
  "322112",
  "322121",
  "322122",
  "322130",
  "322211",
  "322212",
  "322219",
  "322220",
  "322230",
  "322291",
  "322299",
  "323113",
  "323114",
  "323115",
  "323116",
  "323119",
  "323120",
  "324110",
  "324121",
  "324122",
  "324190",
  "325110",
  "325120",
  "325130",
  "325181",
  "325189",
  "325190",
  "325210",
  "325220",
  "325313",
  "325314",
  "325320",
  "325410",
  "325510",
  "325520",
  "325610",
  "325620",
  "325910",
  "325920",
  "325991",
  "325999",
  "326111",
  "326114",
  "326121",
  "326122",
  "326130",
  "326140",
  "326150",
  "326160",
  "326191",
  "326193",
  "326196",
  "326198",
  "326210",
  "326220",
  "326290",
  "327110",
  "327120",
  "327214",
  "327215",
  "327310",
  "327320",
  "327330",
  "327390",
  "327410",
  "327420",
  "327910",
  "327990",
  "331110",
  "331210",
  "331221",
  "331222",
  "331313",
  "331317",
  "331410",
  "331420",
  "331490",
  "331511",
  "331514",
  "331523",
  "331529",
  "332113",
  "332118",
  "332210",
  "332311",
  "332314",
  "332319",
  "332321",
  "332329",
  "332410",
  "332420",
  "332431",
  "332439",
  "332510",
  "332611",
  "332619",
  "332710",
  "332720",
  "332810",
  "332910",
  "332991",
  "332999",
  "333110",
  "333120",
  "333130",
  "333245",
  "333246",
  "333247",
  "333248",
  "333310",
  "333413",
  "333416",
  "333511",
  "333519",
  "333611",
  "333619",
  "333910",
  "333920",
  "333990",
  "334110",
  "334210",
  "334220",
  "334290",
  "334310",
  "334410",
  "334511",
  "334512",
  "334610",
  "335110",
  "335120",
  "335210",
  "335223",
  "335229",
  "335311",
  "335312",
  "335315",
  "335910",
  "335920",
  "335930",
  "335990",
  "336110",
  "336120",
  "336211",
  "336212",
  "336215",
  "336310",
  "336320",
  "336330",
  "336340",
  "336350",
  "336360",
  "336370",
  "336390",
  "336410",
  "336510",
  "336611",
  "336612",
  "336990",
  "337110",
  "337121",
  "337123",
  "337126",
  "337127",
  "337213",
  "337214",
  "337215",
  "337910",
  "337920",
  "339110",
  "339910",
  "339920",
  "339930",
  "339940",
  "339950",
  "339990",
  "411110",
  "411120",
  "411130",
  "411190",
  "412110",
  "413110",
  "413120",
  "413130",
  "413140",
  "413150",
  "413160",
  "413190",
  "413210",
  "413220",
  "413310",
  "413410",
  "414110",
  "414120",
  "414130",
  "414210",
  "414220",
  "414310",
  "414320",
  "414330",
  "414390",
  "414410",
  "414420",
  "414430",
  "414440",
  "414450",
  "414460",
  "414470",
  "414510",
  "414520",
  "415110",
  "415120",
  "415190",
  "415210",
  "415290",
  "415310",
  "416110",
  "416120",
  "416210",
  "416310",
  "416320",
  "416330",
  "416340",
  "416390",
  "417110",
  "417210",
  "417220",
  "417230",
  "417310",
  "417320",
  "417910",
  "417920",
  "417930",
  "417990",
  "418110",
  "418120",
  "418190",
  "418210",
  "418220",
  "418310",
  "418320",
  "418390",
  "418410",
  "418910",
  "418920",
  "418930",
  "418990",
  "419110",
  "419120",
  "441110",
  "441120",
  "441210",
  "441220",
  "441310",
  "441320",
  "442110",
  "442210",
  "442291",
  "442292",
  "442298",
  "443143",
  "443144",
  "443145",
  "443146",
  "444110",
  "444120",
  "444130",
  "444190",
  "444210",
  "444220",
  "445110",
  "445120",
  "445210",
  "445220",
  "445230",
  "445291",
  "445292",
  "445299",
  "445310",
  "446110",
  "446120",
  "446130",
  "446191",
  "446199",
  "447110",
  "447190",
  "448110",
  "448120",
  "448130",
  "448140",
  "448150",
  "448191",
  "448199",
  "448210",
  "448310",
  "448320",
  "451111",
  "451112",
  "451113",
  "451119",
  "451120",
  "451130",
  "451140",
  "451310",
  "452110",
  "452910",
  "452991",
  "452999",
  "453110",
  "453210",
  "453220",
  "453310",
  "453910",
  "453920",
  "453930",
  "453992",
  "453993",
  "453999",
  "454110",
  "454210",
  "454311",
  "454312",
  "454319",
  "454390",
  "481110",
  "481214",
  "481215",
  "482112",
  "482113",
  "482114",
  "483115",
  "483116",
  "483213",
  "483214",
  "484110",
  "484121",
  "484122",
  "484210",
  "484221",
  "484222",
  "484223",
  "484229",
  "484231",
  "484232",
  "484233",
  "484239",
  "485110",
  "485210",
  "485310",
  "485320",
  "485410",
  "485510",
  "485990",
  "486110",
  "486210",
  "486910",
  "486990",
  "487110",
  "487210",
  "487990",
  "488111",
  "488119",
  "488190",
  "488210",
  "488310",
  "488320",
  "488331",
  "488332",
  "488339",
  "488390",
  "488410",
  "488490",
  "488511",
  "488519",
  "488990",
  "491110",
  "492110",
  "492210",
  "493110",
  "493120",
  "493130",
  "493190",
  "511110",
  "511120",
  "511130",
  "511140",
  "511190",
  "511211",
  "511212",
  "512110",
  "512120",
  "512130",
  "512190",
  "512230",
  "512240",
  "512250",
  "512290",
  "515110",
  "515120",
  "515210",
  "517310",
  "517410",
  "517911",
  "517919",
  "518210",
  "519110",
  "519121",
  "519122",
  "519130",
  "519190",
  "521110",
  "522111",
  "522112",
  "522130",
  "522190",
  "522210",
  "522220",
  "522291",
  "522299",
  "522310",
  "522321",
  "522329",
  "522390",
  "523110",
  "523120",
  "523130",
  "523140",
  "523210",
  "523910",
  "523920",
  "523930",
  "523990",
  "524111",
  "524112",
  "524121",
  "524122",
  "524123",
  "524124",
  "524125",
  "524129",
  "524131",
  "524132",
  "524133",
  "524134",
  "524135",
  "524139",
  "524210",
  "524291",
  "524299",
  "526111",
  "526112",
  "526911",
  "526912",
  "526913",
  "526914",
  "526915",
  "526916",
  "526917",
  "526919",
  "526930",
  "526981",
  "526989",
  "531111",
  "531112",
  "531120",
  "531130",
  "531190",
  "531211",
  "531212",
  "531310",
  "531320",
  "531390",
  "532111",
  "532112",
  "532120",
  "532210",
  "532280",
  "532310",
  "532410",
  "532420",
  "532490",
  "533110",
  "541110",
  "541120",
  "541190",
  "541212",
  "541213",
  "541215",
  "541310",
  "541320",
  "541330",
  "541340",
  "541350",
  "541360",
  "541370",
  "541380",
  "541410",
  "541420",
  "541430",
  "541490",
  "541514",
  "541515",
  "541611",
  "541612",
  "541619",
  "541620",
  "541690",
  "541710",
  "541720",
  "541810",
  "541820",
  "541830",
  "541840",
  "541850",
  "541860",
  "541870",
  "541891",
  "541899",
  "541910",
  "541920",
  "541930",
  "541940",
  "541990",
  "551113",
  "551114",
  "561110",
  "561210",
  "561310",
  "561320",
  "561330",
  "561410",
  "561420",
  "561430",
  "561440",
  "561450",
  "561490",
  "561510",
  "561520",
  "561590",
  "561611",
  "561612",
  "561613",
  "561621",
  "561622",
  "561710",
  "561721",
  "561722",
  "561730",
  "561740",
  "561791",
  "561799",
  "561910",
  "561920",
  "561990",
  "562110",
  "562210",
  "562910",
  "562920",
  "562990",
  "611110",
  "611210",
  "611310",
  "611410",
  "611420",
  "611430",
  "611510",
  "611610",
  "611620",
  "611630",
  "611690",
  "611710",
  "621110",
  "621210",
  "621310",
  "621320",
  "621330",
  "621340",
  "621390",
  "621410",
  "621420",
  "621494",
  "621499",
  "621510",
  "621610",
  "621911",
  "621912",
  "621990",
  "622111",
  "622112",
  "622210",
  "622310",
  "623110",
  "623210",
  "623221",
  "623222",
  "623310",
  "623991",
  "623992",
  "623993",
  "623999",
  "624110",
  "624120",
  "624190",
  "624210",
  "624220",
  "624230",
  "624310",
  "624410",
  "711111",
  "711112",
  "711120",
  "711130",
  "711190",
  "711213",
  "711214",
  "711215",
  "711217",
  "711311",
  "711319",
  "711321",
  "711322",
  "711329",
  "711411",
  "711412",
  "711511",
  "711512",
  "711513",
  "712111",
  "712115",
  "712119",
  "712120",
  "712130",
  "712190",
  "713110",
  "713120",
  "713210",
  "713291",
  "713299",
  "713910",
  "713920",
  "713930",
  "713940",
  "713950",
  "713991",
  "713992",
  "713999",
  "721111",
  "721112",
  "721113",
  "721114",
  "721120",
  "721191",
  "721192",
  "721198",
  "721211",
  "721212",
  "721213",
  "721310",
  "722310",
  "722320",
  "722330",
  "722410",
  "722511",
  "722512",
  "811111",
  "811112",
  "811119",
  "811121",
  "811122",
  "811192",
  "811199",
  "811210",
  "811310",
  "811411",
  "811412",
  "811420",
  "811430",
  "811490",
  "812114",
  "812115",
  "812116",
  "812190",
  "812210",
  "812220",
  "812310",
  "812320",
  "812330",
  "812910",
  "812921",
  "812922",
  "812930",
  "812990",
  "813110",
  "813210",
  "813310",
  "813410",
  "813910",
  "813920",
  "813930",
  "813940",
  "813990",
  "814110",
  "911110",
  "911210",
  "911220",
  "911230",
  "911240",
  "911290",
  "911310",
  "911320",
  "911390",
  "911410",
  "911420",
  "911910",
  "912110",
  "912120",
  "912130",
  "912140",
  "912150",
  "912190",
  "912210",
  "912910",
  "913110",
  "913120",
  "913130",
  "913140",
  "913150",
  "913190",
  "913910",
  "914110",
  "919110",
  "31-33",
  "44-45",
  "48-49",
  "236115",
  "236116",
  "236117",
  "236118",
];
