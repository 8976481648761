import React from "react";
import { FunctionalButton } from 'components/functionalButton';
import { Popup } from 'components/popup';
import { CloseButton } from "components/closeButton";
import PylonIcon from "assets/common/pylonIcon.png";
import WipErrorImage from "assets/common/wip.jpg";

import {
  TitleDiv,
  TitleText,
  PopupContainer
} from "pages/referenceModule/rightComponents/styles.js";


export const LoadingMessage = ({
  openPopup,
  setOpenPopup,
  isMobile,
  description,
  isFormatIssue
}) => {


  const handleDone = () => {
    setOpenPopup(false);
  };

  const errorImageDiv = (
    <div
      style={{
        width: "100%",
        height: "120px", 
        backgroundImage: `url(${WipErrorImage})`,
        backgroundSize: "contain", // Ensures the image fits within the div without being cropped
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
      }}
    />
  );

  const icon = <img src={PylonIcon} />;



  return (
    <Popup
      open={openPopup}
      setOpen={setOpenPopup}
      width={isMobile ? "100%" : "540px"}
      height={isMobile ? "100vh" : "400px"}
      dialogContent={(
        <>
          <CloseButton handleClose={handleDone} />
          <PopupContainer>
            <TitleDiv>
              {icon}
            </TitleDiv>
            {isFormatIssue && (
              <>
                <TitleText fontSize="18px" fontWeight="600" color="black" textAlign="center" margin="30px 20px 16px">{description}</TitleText>    
              </>
            )}

            {!isFormatIssue && (
              <>
                <TitleText fontSize="18px" fontWeight="600" color="black" textAlign="center" margin="30px 20px 16px">{description}</TitleText>
                {/* <FunctionalButton
                  buttonTitle="GO BACK"
                  handleButton={handleDone}
                  isReversedColor={true}
                  height="48px"
                  width="150px"
                  marginTop="40px"
                  marginRight="10px"
                  marginLeft="200px"
                /> */}
              </>
            )}

          </PopupContainer>

        </>
      )}
    />

  )
}
