import React, { useState } from "react";

import {
  CloseButton,
  FormTitle,
  InfoArea,
  StyledPaper
} from "./styles";

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Input from "../inputField";
import PledgxLogo from "../../assets/common/pledgxLogo";
import { FunctionalButton } from "../functionalButton";
import { addFinancialDetails } from "api/external_data.js";
import Select from "../../components/selectField";
import countriesInArray from "../../assets/locale/countriesInArray";
import updateObjectByPath from "../../common/updateObjectByPath";

export const AddFinancialDetailsPopup = ({
  setOpenDialog,
  openDialog,
  accountId,
  statementDates,
  isMobile,
  label,
  buttonLabel,
  successAddPopup,
  setSuccessAddPopup
}) => {
  const [data, setData] = useState({});

  const numberOfMonths = [
    "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12",
    "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"
  ];

  const financialStatementTypes = ["Audited","Compilation Report", "Unaudited/Inhouse"]

  const onChangeData = (attribute, val) => {
    const updatedData = { ...data };
    updateObjectByPath(updatedData, attribute, val);
    setData({ ...updatedData });
  };



  const handleSave = () => {
      const updateData = {...data, account_id: accountId}
      addFinancialDetails(
        updateData,
        () => {
          setOpenDialog(false);
          setSuccessAddPopup(true);
          setData({});
        },
        () => { }
      )
  };


  const handleClose = () => {
    setOpenDialog(false);
  };


  return (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <StyledPaper elevation={0} isMobile={isMobile}>
        <CloseButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
        <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />
        <FormTitle style={{ marginTop: 10 }}>
          {label || "Add Details"}
        </FormTitle>
        <InfoArea isMobile={isMobile} marginTop="0px">
          <div className="inputWrap">
            <Select
              style={{ marginRight: "0px", width: "180px" }}
              label="Statement Date*"
              options={statementDates.map((statementDate) => ({
                value: statementDate,
                label: statementDate,
              }))}
              value={data?.statement_date}
              setValue={(val) => onChangeData("statement_date", val)}
              validateFunc={(item) => Boolean(item)}
              noCheckIcon={true}
              showDropdownIndicator={true}
            />
          </div>

          <Input
            id="inputWrap last"
            label="Auditor/Accountant"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={data?.auditor_accountant}
            setValue={(val) => onChangeData("auditor_accountant", val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
          />

          <div className="inputWrap">
            <Select
              style={{ marginRight: "0px", width: "180px" }}
              label="Financial Statement Type"
              options={financialStatementTypes.map((financialStatementType) => ({
                value: financialStatementType,
                label: financialStatementType,
              }))}
              value={data?.financial_statement_type}
              setValue={(val) => onChangeData("financial_statement_type", val)}
              validateFunc={(item) => Boolean(item)}
              noCheckIcon={true}
              showDropdownIndicator={true}
            />
          </div>

          <div className="inputWrap">
            <Select
              style={{ marginRight: "0px", width: "180px" }}
              label="Number of Months"
              options={numberOfMonths.map((numberOfMonth) => ({
                value: numberOfMonth,
                label: numberOfMonth,
              }))}
              value={data?.number_of_months}
              setValue={(val) => onChangeData("number_of_months", val)}
              validateFunc={(item) => Boolean(item)}
              noCheckIcon={true}
              showDropdownIndicator={true}
            />
          </div>

        </InfoArea>

        <FunctionalButton
          width="90%"
          marginTop="16px"
          marginRight="10px"
          handleButton={() => {
            handleSave();
          }}
          buttonTitle={buttonLabel || "ADD"}
        />
      </StyledPaper>
    </Dialog>
  );
};
