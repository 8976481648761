const getUniqueReportingYears = (entities) => {
  let uniqueReportingYears = new Set();

  entities
    .filter((entity) => entity.type === "year")
    .forEach(({ properties }) => {
      properties.forEach(({ type, mentionText }) => {
        if (type === "reporting-year") uniqueReportingYears.add(mentionText);
      });
    });

  return [...uniqueReportingYears];
};

const sanitizeValue = (value) => {
  if (!value) return 0;
  const cleanedValue = value.replace(/[\s,()]/g, "");
  return value.startsWith("(") || value.startsWith("-")
    ? -parseInt(cleanedValue)
    : parseInt(cleanedValue);
};

const sumPropertyValues = (properties, type) => {
  const filteredProperties = properties.filter((prop) => prop.type === type);
  return filteredProperties.length > 0
    ? filteredProperties
        .reduce((sum, { mentionText }) => {
          const numericValue = parseInt(mentionText.replace(/[\s,()]/g, ""));
          return sum + (isNaN(numericValue) ? 0 : numericValue);
        }, 0)
        .toString()
    : "0";
};

/**
 * Validation utility for financial documents.
 *
 * Provides multiple validation methods for verifying the accuracy of financial data
 * across various financial statements such as Balance Sheet, Profit and Loss,
 * Cash Flow, Work in Progress, and Work Completed.
 *
 * @typedef {Object} ValidationResult
 * @property {boolean} succeeded - Indicates whether the validation passed (`true`) or failed (`false`).
 * @property {string} message - Provides a detailed message explaining the validation result or the reason for failure.
 *
 * @type {Object}
 * @property {function(Object): ValidationResult} balanceSheet - Validates the balance sheet data.
 * @property {function(Object, function): ValidationResult} profitAndLoss - Validates the profit and loss data.
 * @property {function(Object): ValidationResult} cashflow - Validates the cash flow data.
 * @property {function(Object): ValidationResult} workInProgress - Validates work-in-progress data.
 * @property {function(Object): ValidationResult} workCompleted - Validates work-completed data.
 */
export const isValidationSuccessful = {
  balanceSheet: (splitFilesData) => {
    console.log("called val for balance sheet");

    let failed = [];

    const matchedSplit = splitFilesData.find(
      (data) => data.splitFiles[0].labellerModel === "balanceSheet",
    );

    if (!matchedSplit) {
      return { succeeded: true };
    }

    let formula = getUniqueReportingYears(
      matchedSplit.splitFiles[0].labellerOutput.entities,
    ).map((year) => ({
      reportingYear: year,
      totalAssets: "",
      cash: "",
      marketableSecurities: "",
      accountsRecievableProgress: "",
      accountsRecievableRetention: "",
      defferedAssets: "",
      inventory: "",
      prepaidExpensesCurrent: "",
      otherCurrentAssets: "",
      totalCurrentAssets: "",
      prepaidExpensesNonCurrent: "",
      accountsRecievableOverNinetyDays: "",
      plantsAndEquipment: "",
      machineryAndVehicles: "",
      furnitureAndFixture: "",
      realEstate: "",
      otherFixedAssets: "",
      accumulatedDepreciation: "",
      otherNonCurrentAssets: "",
      assetsOnLease: "",
      cashRestricted: "",
      shareholderLoans: "",
      totalLiabilityAndNetworth: "",
      assetsWrittenOff: "",
      loanPayable: "",
      billsPayable: "",
      accountsPayableProgress: "",
      accountsPayableRetention: "",
      otherPayables: "",
      deferredRevenue: "",
      currentPortionLongTermDebt: "",
      currentLeaseLiability: "",
      employeeLoans: "",
      currentTaxes: "",
      deferredTaxes: "",
      loansFromShareholders: "",
      accruedExpenses: "",
      totalCurrentLiabilities: "",
      longTermLoanPayable: "",
      longTermLeaseLiability: "",
      longTermDebtOther: "",
      longTermDeferredTax: "",
      otherLiabilities: "",
      minorityInterest: "",
      totalLiabilities: "",
      subordinatedDebt: "",
      shareCapital: "",
      intangibleAssets: "",
      retainedEarnings: "",
      netWorth: "",
      otherEquity: "",
    }));

    getUniqueReportingYears(
      matchedSplit.splitFiles[0].labellerOutput.entities,
    ).forEach((year, index) => {
      const matchedYearEntities =
        matchedSplit.splitFiles[0].labellerOutput.entities.filter((entity) => {
          return Boolean(
            entity.properties.find(
              (prop) =>
                prop.type === "reporting-year" && prop.mentionText === year,
            ),
          );
        });

      const mergedProperties = matchedYearEntities.flatMap(
        (entity) => entity.properties,
      );

      formula[index].cash = sumPropertyValues(mergedProperties, "cash");
      formula[index].totalAssets = sumPropertyValues(
        mergedProperties,
        "total-assets",
      );
      formula[index].marketableSecurities = sumPropertyValues(
        mergedProperties,
        "marketable-securities",
      );
      formula[index].accountsRecievableProgress = sumPropertyValues(
        mergedProperties,
        "accounts-receivable-progress",
      );
      formula[index].accountsRecievableRetention = sumPropertyValues(
        mergedProperties,
        "accounts-receivable-retention",
      );
      formula[index].defferedAssets = sumPropertyValues(
        mergedProperties,
        "deferred-assets",
      );
      formula[index].inventory = sumPropertyValues(
        mergedProperties,
        "inventory",
      );
      formula[index].prepaidExpensesCurrent = sumPropertyValues(
        mergedProperties,
        "prepaid-expenses-current",
      );
      formula[index].otherCurrentAssets = sumPropertyValues(
        mergedProperties,
        "other-current-assets",
      );
      formula[index].totalCurrentAssets = sumPropertyValues(
        mergedProperties,
        "total-current-assets",
      );
      formula[index].prepaidExpensesNonCurrent = sumPropertyValues(
        mergedProperties,
        "prepaid-expenses-non-current",
      );
      formula[index].accountsRecievableOverNinetyDays = sumPropertyValues(
        mergedProperties,
        "accounts-receivable-over-90days",
      );
      formula[index].plantsAndEquipment = sumPropertyValues(
        mergedProperties,
        "plant-and-equipment",
      );
      formula[index].machineryAndVehicles = sumPropertyValues(
        mergedProperties,
        "machinery-and-vehicles",
      );
      formula[index].furnitureAndFixture = sumPropertyValues(
        mergedProperties,
        "furniture-and-fixture",
      );
      formula[index].realEstate = sumPropertyValues(
        mergedProperties,
        "real-estate",
      );
      formula[index].otherFixedAssets = sumPropertyValues(
        mergedProperties,
        "other-fixed-assets",
      );
      formula[index].accumulatedDepreciation = sumPropertyValues(
        mergedProperties,
        "accumulated-depreciation",
      );
      formula[index].otherNonCurrentAssets = sumPropertyValues(
        mergedProperties,
        "other-non-current-assets",
      );
      formula[index].assetsOnLease = sumPropertyValues(
        mergedProperties,
        "assets-on-lease",
      );
      formula[index].cashRestricted = sumPropertyValues(
        mergedProperties,
        "cash-restricted",
      );
      formula[index].shareholderLoans = sumPropertyValues(
        mergedProperties,
        "shareholder-loans",
      );
      formula[index].totalLiabilityAndNetworth = sumPropertyValues(
        mergedProperties,
        "total-liability-net-worth",
      );
      formula[index].assetsWrittenOff = sumPropertyValues(
        mergedProperties,
        "assets-written-off",
      );
      formula[index].loanPayable = sumPropertyValues(
        mergedProperties,
        "loan-payable",
      );
      formula[index].billsPayable = sumPropertyValues(
        mergedProperties,
        "bills-payable",
      );
      formula[index].accountsPayableProgress = sumPropertyValues(
        mergedProperties,
        "accounts-payable-progress",
      );
      formula[index].accountsPayableRetention = sumPropertyValues(
        mergedProperties,
        "accounts-payable-retention",
      );
      formula[index].otherPayables = sumPropertyValues(
        mergedProperties,
        "other-payables",
      );
      formula[index].deferredRevenue = sumPropertyValues(
        mergedProperties,
        "deferred-revenue",
      );
      formula[index].currentPortionLongTermDebt = sumPropertyValues(
        mergedProperties,
        "current-portion-long-term-debt",
      );
      formula[index].currentLeaseLiability = sumPropertyValues(
        mergedProperties,
        "current-lease-liability",
      );
      formula[index].employeeLoans = sumPropertyValues(
        mergedProperties,
        "employee-loans",
      );
      formula[index].currentTaxes = sumPropertyValues(
        mergedProperties,
        "current-taxes",
      );
      formula[index].deferredTaxes = sumPropertyValues(
        mergedProperties,
        "deferred-taxes",
      );
      formula[index].loansFromShareholders = sumPropertyValues(
        mergedProperties,
        "loans-from-shareholders",
      );
      formula[index].accruedExpenses = sumPropertyValues(
        mergedProperties,
        "accrued-expenses",
      );
      formula[index].totalCurrentLiabilities = sumPropertyValues(
        mergedProperties,
        "total-current-liabilities",
      );
      formula[index].longTermLoanPayable = sumPropertyValues(
        mergedProperties,
        "long-term-loan-payable",
      );
      formula[index].longTermLeaseLiability = sumPropertyValues(
        mergedProperties,
        "long-term-lease-liability",
      );
      formula[index].longTermDebtOther = sumPropertyValues(
        mergedProperties,
        "long-term-debt-other",
      );
      formula[index].longTermDeferredTax = sumPropertyValues(
        mergedProperties,
        "long-term-deferred-tax",
      );
      formula[index].otherLiabilities = sumPropertyValues(
        mergedProperties,
        "other-liabilities",
      );
      formula[index].minorityInterest = sumPropertyValues(
        mergedProperties,
        "minority-interest",
      );
      formula[index].totalLiabilities = sumPropertyValues(
        mergedProperties,
        "total-liabilities",
      );
      formula[index].subordinatedDebt = sumPropertyValues(
        mergedProperties,
        "subordinated-debt",
      );
      formula[index].shareCapital = sumPropertyValues(
        mergedProperties,
        "share-capital",
      );
      formula[index].intangibleAssets = sumPropertyValues(
        mergedProperties,
        "intangible-assets",
      );
      formula[index].retainedEarnings = sumPropertyValues(
        mergedProperties,
        "retained-earnings",
      );
      formula[index].netWorth = sumPropertyValues(
        mergedProperties,
        "net-worth",
      );
      formula[index].otherEquity = sumPropertyValues(
        mergedProperties,
        "other-equity",
      );
    });

    for (const year of formula) {
      const totalAssets = parseInt(year.totalAssets.replace(/,/g, ""));

      const totalLiabilityAndNetworth = parseInt(
        year.totalLiabilityAndNetworth.replace(/,/g, ""),
      );

      // Validation failed for the reporting year 2023. Total assets (23952000) do not match the calculated value (40019000).

      const sumA =
        sanitizeValue(year.cash) +
        sanitizeValue(year.marketableSecurities) +
        sanitizeValue(year.accountsRecievableProgress) +
        sanitizeValue(year.accountsRecievableRetention) +
        sanitizeValue(year.defferedAssets) +
        sanitizeValue(year.inventory) +
        sanitizeValue(year.prepaidExpensesCurrent) +
        sanitizeValue(year.otherCurrentAssets) +
        sanitizeValue(year.prepaidExpensesNonCurrent) +
        sanitizeValue(year.accountsRecievableOverNinetyDays) +
        sanitizeValue(year.plantsAndEquipment) +
        sanitizeValue(year.machineryAndVehicles) +
        sanitizeValue(year.furnitureAndFixture) +
        sanitizeValue(year.realEstate) +
        sanitizeValue(year.otherFixedAssets) +
        sanitizeValue(year.accumulatedDepreciation) +
        sanitizeValue(year.otherNonCurrentAssets) +
        sanitizeValue(year.assetsOnLease) +
        sanitizeValue(year.cashRestricted) +
        sanitizeValue(year.shareholderLoans) +
        sanitizeValue(year.intangibleAssets) +
        sanitizeValue(year.assetsWrittenOff);

      console.log(
        "Code 1 ",
        totalAssets,
        " sum A: ",
        sumA,
        " ",
        " year ",
        year,
        " ",
      );

      console.log("Formula Balance Sheet: ", formula);

      if (totalAssets != sumA) {
        failed.push(
          `Validation failed for the reporting year ${year.reportingYear}. The sum of all assets (${sumA.toLocaleString()}) does not equal Total Assets (${totalAssets.toLocaleString()}) as read from the document.`,
        );
      }

      const sumC =
        sanitizeValue(year.loanPayable) +
        sanitizeValue(year.billsPayable) +
        sanitizeValue(year.accountsPayableProgress) +
        sanitizeValue(year.accountsPayableRetention) +
        sanitizeValue(year.otherPayables) +
        sanitizeValue(year.deferredRevenue) +
        sanitizeValue(year.currentPortionLongTermDebt) +
        sanitizeValue(year.currentLeaseLiability) +
        sanitizeValue(year.employeeLoans) +
        sanitizeValue(year.currentTaxes) +
        sanitizeValue(year.deferredTaxes) +
        sanitizeValue(year.loansFromShareholders) +
        sanitizeValue(year.accruedExpenses) +
        sanitizeValue(year.totalCurrentLiabilities) +
        sanitizeValue(year.longTermLoanPayable) +
        sanitizeValue(year.longTermLeaseLiability) +
        sanitizeValue(year.longTermDebtOther) +
        sanitizeValue(year.longTermDeferredTax) +
        sanitizeValue(year.otherLiabilities) +
        sanitizeValue(year.minorityInterest) +
        sanitizeValue(year.totalLiabilities) +
        sanitizeValue(year.subordinatedDebt) +
        sanitizeValue(year.shareCapital) +
        sanitizeValue(year.retainedEarnings) +
        sanitizeValue(year.otherEquity) +
        sanitizeValue(year.netWorth);

      const sumD =
        sanitizeValue(year.totalCurrentLiabilities) +
        sanitizeValue(year.totalLiabilities) +
        sanitizeValue(year.netWorth);

      if (totalLiabilityAndNetworth !== sumC - sumD) {
        failed.push(
          `Validation failed for the reporting year ${year.reportingYear}. The sum of all liabilities and net worth (${(sumC - sumD).toLocaleString()}) does not equal Total Liabilities and Net worth (${totalLiabilityAndNetworth.toLocaleString()}) as read from the document.`,
        );
      }
    }

    if (failed.length > 0) {
      return {
        succeeded: false,
        message: failed,
      };
    }

    return {
      succeeded: true,
    };
  },
  profitAndLoss: (splitFilesData) => {
    let failed = [];
    const matchedSplit = splitFilesData.find(
      (data) => data.splitFiles[0].labellerModel === "profitAndLoss",
    );

    if (!matchedSplit) {
      return { succeeded: true };
    }

    let formula = getUniqueReportingYears(
      matchedSplit.splitFiles[0].labellerOutput.entities,
    ).map(() => ({
      reportingYear: "",
      grossProfit: "",
      grossContractIncome: "",
      managementFeeIncome: "",
      otherOperatingExpense: "",
      intercompanyRevenue: "",
      wageExpense: "",
      subContractExpense: "",
      materialExpense: "",
      equipmentExpense: "",
      otherDirectExpense: "",
      explorationAndEvaluationExpense: "",
      intercompanyDirectExpense: "",
      operatingProfit: "",
      totalOverheadExpense: "",
      adminExpense: "",
      otherNonOperatingExpense: "",
      salaryAndBonus: "",
      depreciation: "",
      intercompanyOverheadExpense: "",
      netIncomeAfterTax: "",
      otherIncome: "",
      discretionaryBonus: "",
      otherOperatingIncome: "",
      interestExpense: "",
      incomeTaxCurrent: "",
      incomeTaxDeferred: "",
    }));

    getUniqueReportingYears(
      matchedSplit.splitFiles[0].labellerOutput.entities,
    ).forEach((year, index) => {
      const matchedYearEntities =
        matchedSplit.splitFiles[0].labellerOutput.entities.filter((entity) => {
          return Boolean(
            entity.properties.find(
              (prop) =>
                prop.type === "reporting-year" && prop.mentionText === year,
            ),
          );
        });

      const mergedProperties = matchedYearEntities.flatMap(
        (entity) => entity.properties,
      );

      formula[index].reportingYear = sumPropertyValues(
        mergedProperties,
        "reporting-year",
      );
      formula[index].grossProfit = sumPropertyValues(
        mergedProperties,
        "gross-profit",
      );
      formula[index].grossContractIncome = sumPropertyValues(
        mergedProperties,
        "gross-contract-income",
      );
      formula[index].managementFeeIncome = sumPropertyValues(
        mergedProperties,
        "management-fee-income",
      );
      formula[index].otherOperatingExpense = sumPropertyValues(
        mergedProperties,
        "other-operating-expense",
      );
      formula[index].intercompanyRevenue = sumPropertyValues(
        mergedProperties,
        "intercompany-revenue",
      );
      formula[index].wageExpense = sumPropertyValues(
        mergedProperties,
        "wage-expense",
      );
      formula[index].subContractExpense = sumPropertyValues(
        mergedProperties,
        "subcontract-expense",
      );
      formula[index].materialExpense = sumPropertyValues(
        mergedProperties,
        "material-expense",
      );
      formula[index].equipmentExpense = sumPropertyValues(
        mergedProperties,
        "equipment-expense",
      );
      formula[index].otherDirectExpense = sumPropertyValues(
        mergedProperties,
        "other-direct-expenses",
      );
      formula[index].explorationAndEvaluationExpense = sumPropertyValues(
        mergedProperties,
        "exploration-and-evaluation-expense",
      );
      formula[index].intercompanyDirectExpense = sumPropertyValues(
        mergedProperties,
        "intercompany-direct-expense",
      );
      formula[index].operatingProfit = sumPropertyValues(
        mergedProperties,
        "operating-profit",
      );
      formula[index].totalOverheadExpense = sumPropertyValues(
        mergedProperties,
        "total-overhead-expense",
      );
      formula[index].adminExpense = sumPropertyValues(
        mergedProperties,
        "admin-expense",
      );
      formula[index].otherNonOperatingExpense = sumPropertyValues(
        mergedProperties,
        "other-non-operating-expense",
      );
      formula[index].salaryAndBonus = sumPropertyValues(
        mergedProperties,
        "salary-and-bonus",
      );
      formula[index].depreciation = sumPropertyValues(
        mergedProperties,
        "depreciation",
      );
      formula[index].intercompanyOverheadExpense = sumPropertyValues(
        mergedProperties,
        "intercompany-overhead-expense",
      );
      formula[index].netIncomeAfterTax = sumPropertyValues(
        mergedProperties,
        "net-income-after-tax",
      );
      formula[index].otherIncome = sumPropertyValues(
        mergedProperties,
        "other-income",
      );
      formula[index].discretionaryBonus = sumPropertyValues(
        mergedProperties,
        "discretionary-bonus",
      );
      formula[index].otherOperatingIncome = sumPropertyValues(
        mergedProperties,
        "other-operating-income",
      );
      formula[index].interestExpense = sumPropertyValues(
        mergedProperties,
        "interest-expense",
      );
      formula[index].incomeTaxCurrent = sumPropertyValues(
        mergedProperties,
        "income-tax-current",
      );
      formula[index].incomeTaxDeferred = sumPropertyValues(
        mergedProperties,
        "income-tax-deferred",
      );
    });

    console.log("formula p&l", formula);

    // Validations
    for (const year of formula) {
      const sumA1 =
        Math.abs(parseInt(year.grossContractIncome.replace(/[\s,()]/g, ""))) +
        Math.abs(parseInt(year.managementFeeIncome.replace(/[\s,()]/g, ""))) +
        Math.abs(parseInt(year.otherOperatingIncome.replace(/[\s,()]/g, ""))) +
        Math.abs(parseInt(year.intercompanyRevenue.replace(/[\s,()]/g, "")));

      const sumB1 =
        Math.abs(parseInt(year.wageExpense.replace(/[\s,()]/g, ""))) +
        Math.abs(parseInt(year.subContractExpense.replace(/[\s,()]/g, ""))) +
        Math.abs(parseInt(year.materialExpense.replace(/[\s,()]/g, ""))) +
        Math.abs(parseInt(year.equipmentExpense.replace(/[\s,()]/g, ""))) +
        Math.abs(parseInt(year.otherDirectExpense.replace(/[\s,()]/g, ""))) +
        Math.abs(
          parseInt(
            year.explorationAndEvaluationExpense.replace(/[\s,()]/g, ""),
          ),
        ) +
        Math.abs(
          parseInt(year.intercompanyDirectExpense.replace(/[\s,()]/g, "")),
        );

      const calculatedGrossProfit = sumA1 - sumB1;

      if (
        calculatedGrossProfit !==
        Math.abs(parseInt(year.grossProfit.replace(/[\s,()]/g, "")))
      ) {
        failed.push(
          `Validation failed for the reporting year ${year.reportingYear}. The sum of all Income items less the sum of all Direct Expense items (${calculatedGrossProfit.toLocaleString()}) does not equal Gross Profit (${parseInt(year.grossProfit.replace(/,/g, "")).toLocaleString()}) as read from the document.`,
        );
      }

      const totalOverheadExpense = parseInt(
        year.totalOverheadExpense.replace(/[\s,()]/g, ""),
      );
      const operatingProfit = parseInt(
        year.operatingProfit.replace(/[\s,()]/g, ""),
      );
      const grossProfit = parseInt(year.grossProfit.replace(/[\s,()]/g, ""));

      const adminExpense = parseInt(year.adminExpense.replace(/[\s,()]/g, ""));
      const otherNonOperatingExpense = parseInt(
        year.otherNonOperatingExpense.replace(/[\s,()]/g, ""),
      );
      const salaryAndBonus = parseInt(
        year.salaryAndBonus.replace(/[\s,()]/g, ""),
      );
      const depreciation = parseInt(year.depreciation.replace(/[\s,()]/g, ""));
      const intercompanyOverheadExpense = parseInt(
        year.intercompanyOverheadExpense.replace(/[\s,()]/g, ""),
      );
      const otherIncome = parseInt(year.otherIncome.replace(/[\s,()]/g, ""));
      const netIncomeAfterTax = parseInt(
        year.netIncomeAfterTax.replace(/[\s,()]/g, ""),
      );
      const discretionaryBonus = parseInt(
        year.discretionaryBonus.replace(/[\s,()]/g, ""),
      );
      const grossContractIncome = parseInt(
        year.grossContractIncome.replace(/[\s,()]/g, ""),
      );

      const sumA2 =
        Math.abs(grossProfit) -
        (Math.abs(adminExpense) +
          Math.abs(otherNonOperatingExpense) +
          Math.abs(salaryAndBonus) +
          Math.abs(depreciation) +
          Math.abs(intercompanyOverheadExpense));

      const sumA3 =
        Math.abs(adminExpense) +
        Math.abs(otherNonOperatingExpense) +
        Math.abs(salaryAndBonus) +
        Math.abs(depreciation) +
        Math.abs(intercompanyOverheadExpense) +
        Math.abs(totalOverheadExpense);

      if (totalOverheadExpense != 0) {
        if (grossProfit - sumA3 !== operatingProfit) {
          failed.push(
            `Validation failed for the reporting year ${year.reportingYear}. Gross Profit (less) Total Overhead Expense (${(grossProfit - totalOverheadExpense).toLocaleString()}) does not equal Operating Profit (${operatingProfit.toLocaleString()}) as read from the document.`,
          );
        }
      } else {
        if (sumA2 !== operatingProfit) {
          failed.push(
            `Validation failed for the reporting year ${year.reportingYear}. Gross Profit (less) all Indirect Expenses (${sumA2.toLocaleString()}) does not equal Operating Profit (${operatingProfit.toLocaleString()}) as read from the document.`,
          );
        }
      }

      const interestExpense = parseInt(
        year.interestExpense.replace(/[\s,()]/g, ""),
      );

      const incomeTaxCurrent = parseInt(
        year.incomeTaxCurrent.replace(/[\s,()]/g, ""),
      );

      const incomeTaxDeferred = parseInt(
        year.incomeTaxDeferred.replace(/[\s,()]/g, ""),
      );

      console.log("P & L");
      console.log("Operating Profit:", operatingProfit);
      console.log("Other Income:", otherIncome);
      console.log("Discretionary Bonus:", discretionaryBonus);
      console.log("Interest Expense:", interestExpense);
      console.log("Income Tax Current:", incomeTaxCurrent);
      console.log("Income Tax Deferred:", incomeTaxDeferred);

      const result =
        operatingProfit +
        otherIncome -
        (discretionaryBonus +
          interestExpense +
          incomeTaxCurrent +
          incomeTaxDeferred);

      console.log("Final Result:", result);

      if (operatingProfit !== 0) {
        if (netIncomeAfterTax !== result) {
          failed.push(
            `Validation failed for the reporting year ${year.reportingYear}. Operating Profit (plus) Other Income (less) Non-Operating Expenses (${result.toLocaleString()}) does not match the value of Net Income After Tax (${year.netIncomeAfterTax.toLocaleString()}) as read from the document.`,
          );
        }
      } else {
        if (
          netIncomeAfterTax !==
          sumA2 +
            otherIncome -
            (discretionaryBonus +
              interestExpense +
              incomeTaxCurrent +
              incomeTaxDeferred)
        ) {
          failed.push(
            `Validation failed for the reporting year ${year.reportingYear}. Operating Profit (plus) Other Income (less) Non-Operating Expenses (${
              sumA2 +
              otherIncome -
              (
                discretionaryBonus +
                interestExpense +
                incomeTaxCurrent +
                incomeTaxDeferred
              ).toLocaleString()
            }) does not match the value of Net Income After Tax (${year.netIncomeAfterTax.toLocaleString()}) as read from the document.`,
          );
        }
      }
    }

    const workInProgressSplits = splitFilesData.filter(
      (data) => data.splitFiles[0].labellerModel === "workInProgress",
    );
    const workCompletedSplits = splitFilesData.filter(
      (data) => data.splitFiles[0].labellerModel === "workCompleted",
    );

    if (workInProgressSplits.length > 0 || workCompletedSplits.length > 0) {
      const maxYear = Math.max(
        ...getUniqueReportingYears(
          matchedSplit.splitFiles[0].labellerOutput.entities,
        ).map((year) => parseInt(year)),
      ).toString();

      console.log("Max Year: ", maxYear);

      const maxYearProperty =
        matchedSplit.splitFiles[0].labellerOutput.entities.find((entity) => {
          return entity.properties?.some(
            (property) =>
              property.type === "reporting-year" &&
              property.mentionText === maxYear,
          );
        });

      const maxYearGrossContractIncome = maxYearProperty.properties.find(
        (prop) => prop.type === "gross-contract-income",
      ).mentionText;

      console.log(
        `${maxYear} Gross Contract Income: `,
        maxYearGrossContractIncome,
      );

      if (maxYearGrossContractIncome) {
        let currentYearEarnedRevenueSum = 0;

        const wipEntities = workInProgressSplits
          .flatMap((data) => {
            const asOfDate = data.splitFiles[0].labellerOutput.entities.find(
              (e) => e.type === "as_of_date" && e.mentionText.includes(maxYear),
            );
            if (asOfDate) {
              return data.splitFiles[0].labellerOutput.entities;
            }
            return null;
          })
          .filter((data) => data !== null);

        const workCompletedEntities = workCompletedSplits
          .flatMap((data) => {
            const asOfDate = data.splitFiles[0].labellerOutput.entities.find(
              (e) => e.type === "as_of_date" && e.mentionText.includes(maxYear),
            );
            if (asOfDate) {
              return data.splitFiles[0].labellerOutput.entities;
            }
            return null;
          })
          .filter((data) => data !== null);

        console.log("WIP Entities: ", wipEntities);
        console.log("Work Completed Entities: ", workCompletedEntities);

        const wipAndWorkCompletedEntities = [
          ...workCompletedEntities,
          ...wipEntities,
        ];

        if (wipAndWorkCompletedEntities.length > 0) {
          for (const entity of wipAndWorkCompletedEntities) {
            if (entity.type === "project") {
              const currentYearEarnedRevenue =
                entity.properties.find(
                  (prop) => prop.type === "current_year_earned_revenue",
                )?.mentionText || "0";

              console.log(
                "current year earned revenue ",
                currentYearEarnedRevenue,
              );

              if (currentYearEarnedRevenue) {
                currentYearEarnedRevenueSum += parseInt(
                  currentYearEarnedRevenue.replace(/[\s,()]/g, ""),
                );
              }
            }
          }

          if (
            currentYearEarnedRevenueSum !=
            parseInt(maxYearGrossContractIncome.replace(/[\s,()]/g, ""))
          ) {
            failed.push(
              `The sum of current year earned revenue ${currentYearEarnedRevenueSum.toLocaleString()} as calculated from WIP and Work Completed documents does not equal the value of Gross Contract Income ${maxYearGrossContractIncome.toLocaleString()} as read from the Profit and Loss Statement`,
            );
          }
        }
      }
    }

    if (failed.length > 0) {
      return { succeeded: false, message: failed };
    }

    return { succeeded: true };
  },
  cashflow: (splitFilesData) => {
    let failed = [];

    const matchedSplit = splitFilesData.find(
      (data) => data.splitFiles[0].labellerModel === "cashflow",
    );

    if (!matchedSplit) {
      return { succeeded: true };
    }

    let formula = getUniqueReportingYears(
      matchedSplit.splitFiles[0].labellerOutput.entities,
    ).map((year) => ({
      reportingYear: year,
      netIncome: "",
      depreciationCf: "",
      badDebtExpense: "",
      otherNonCash: "",
      incomeTaxDeferredCf: "",
      changeInAssets: "",
      changeInLiabilities: "",
      netCashOperating: "",
      netCashInvesting: "",
      netCashFinancing: "",
      netCashFlow: "",
    }));

    getUniqueReportingYears(
      matchedSplit.splitFiles[0].labellerOutput.entities,
    ).forEach((year, index) => {
      const matchedYearEntities =
        matchedSplit.splitFiles[0].labellerOutput.entities.filter((entity) => {
          return Boolean(
            entity.properties.find(
              (prop) =>
                prop.type === "reporting-year" && prop.mentionText === year,
            ),
          );
        });

      const mergedProperties = matchedYearEntities.flatMap(
        (entity) => entity.properties,
      );

      formula[index].netIncome = sumPropertyValues(
        mergedProperties,
        "net-income",
      );
      formula[index].depreciationCf = sumPropertyValues(
        mergedProperties,
        "depreciation-cf",
      );
      formula[index].badDebtExpense = sumPropertyValues(
        mergedProperties,
        "bad-debt-expense",
      );
      formula[index].otherNonCash = sumPropertyValues(
        mergedProperties,
        "other-non-cash",
      );
      formula[index].incomeTaxDeferredCf = sumPropertyValues(
        mergedProperties,
        "income-tax-deferred-cf",
      );
      formula[index].changeInAssets = sumPropertyValues(
        mergedProperties,
        "change-in-assets",
      );
      formula[index].changeInLiabilities = sumPropertyValues(
        mergedProperties,
        "change-in-liabilities",
      );
      formula[index].netCashOperating = sumPropertyValues(
        mergedProperties,
        "net-cash-operating",
      );
      formula[index].netCashInvesting = sumPropertyValues(
        mergedProperties,
        "net-cash-investing",
      );
      formula[index].netCashFinancing = sumPropertyValues(
        mergedProperties,
        "net-cash-financing",
      );
      formula[index].netCashFlow = sumPropertyValues(
        mergedProperties,
        "net-cash-flow",
      );
    });

    for (const year of formula) {
      const netCashFlow = parseInt(year.netCashFlow.replace(/[\s,()]/g, ""));

      const netCashOperating = parseInt(
        year.netCashOperating.replace(/[\s,()]/g, ""),
      );

      console.log("Change In Assets: ", year.changeInAssets);
      console.log("Change In Liabilities: ", year.changeInLiabilities);

      const sumA =
        parseInt(year.netIncome.replace(/[\s,()]/g, "")) +
        parseInt(year.depreciationCf.replace(/[\s,()]/g, "")) +
        parseInt(year.badDebtExpense.replace(/[\s,()]/g, "")) +
        parseInt(year.otherNonCash.replace(/[\s,()]/g, "")) +
        parseInt(year.incomeTaxDeferredCf.replace(/[\s,()]/g, "")) +
        parseInt(year.changeInAssets.replace(/[\s,()]/g, "")) +
        parseInt(year.changeInLiabilities.replace(/[\s,()]/g, ""));

      if (netCashOperating !== sumA) {
        failed.push(
          `Validation failed for the reporting year ${year.reportingYear}. Net Cash from Operating Activities (${netCashOperating.toLocaleString()}) does not match the calculated sum (${sumA.toLocaleString()}). Please review the components of Net Income, Depreciation, Bad Debt Expense, Other Non-Cash Items, Deferred Income Tax, and changes in Assets and Liabilities.`,
        );
      }

      const sumB =
        sanitizeValue(year.netCashOperating) +
        sanitizeValue(year.netCashInvesting) +
        sanitizeValue(year.netCashFinancing);

      if (netCashFlow != sumB) {
        failed.push(
          `Validation failed for the reporting year ${year.reportingYear}. Net Cash Flow (${netCashFlow.toLocaleString()}) The sum of Net Cash from Operating (${year.netCashOperating.toLocaleString()}), Investing (${year.netCashInvesting.toLocaleString()}), and Financing Activities (${year.netCashFinancing.toLocaleString()}) does not match Net Cash Flow (${year.netCashFlow.toLocaleString()}) as read from the document.`,
        );
      }
    }

    if (failed.length > 0) {
      return {
        succeeded: false,
        message: failed,
      };
    }

    return {
      succeeded: true,
    };
  },
  workInProgress: (splitFilesData) => {
    let failed = [];
    const matchedSplit = splitFilesData.find(
      (data) => data.splitFiles[0].labellerModel === "workInProgress",
    );

    if (!matchedSplit) {
      return { succeeded: true };
    }

    let formula = matchedSplit.splitFiles[0].labellerOutput.entities
      .filter((entity) => entity.type === "project")
      .map((entity) => {
        const projectName =
          entity.properties.find((prop) => prop.type === "project_name") || "0";
        const estimatedGrossProfit = parseInt(
          entity.properties
            .find((prop) => prop.type === "estimated-gross-profit")
            ?.mentionText.replace(/[\s,()]/g, "") || 0,
        );

        const estimatedCostOfRevenue = parseInt(
          entity.properties
            .find((prop) => prop.type === "estimated_cost_of_revenue")
            ?.mentionText.replace(/[\s,()]/g, "") || 0,
        );

        const projectValueOrEstimatedRevenue = parseInt(
          entity.properties
            .find((prop) => prop.type === "project_value_or_estimated_revenue")
            ?.mentionText.replace(/[\s,()]/g, "") || 0,
        );

        return {
          projectName,
          estimatedGrossProfit,
          estimatedCostOfRevenue,
          projectValueOrEstimatedRevenue,
        };
      });

    for (const project of formula) {
      if (
        project.estimatedGrossProfit !==
        project.projectValueOrEstimatedRevenue - project.estimatedCostOfRevenue
      ) {
        failed.push(
          `Validation failed for the project ${project.projectName}. Estimated Gross Profit (${project.estimatedGrossProfit.toLocaleString()}) does not equal Project Value or Estimated Revenue (${project.projectValueOrEstimatedRevenue.toLocaleString()}) minus Estimated Cost of Revenue (${project.estimatedCostOfRevenue.toLocaleString()}). Please review the profit calculation.`,
        );
      }
    }

    if (failed.length > 0) {
      return {
        succeeded: false,
        message: failed,
      };
    }

    return {
      succeeded: true,
    };
  },

  workCompleted: () => {
    return {
      succeeded: true,
    };
  },
};
