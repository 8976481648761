import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "@material-ui/core";
import { CloseButton } from "../../pages/dashboard/styles";
import { StyledPaper } from "../addBankPopup/styles";
import { FunctionalButton } from "../functionalButton";
import { FormTitle } from "../../pages/homePage/styles";
import PledgxLogo from "assets/common/pledgxLogo";
import CloseIcon from "@material-ui/icons/Close";
import { naicsCodes } from "../../pages/underwriters/data/naicsCodes";
import Select from "../selectField";
import Input from "../inputField";
import DateTimePickerField from "../dateTimePickerField";
import { SmallTitleText } from "../../pages/underwriterDashboard/idpDocuments/styles";
import Success from "../../assets/dashboardIcons/Success";
import { AlertPopup } from "../alertPopup/alertPopup";
import { InputLabel } from "./styles";
import { CategoriesRow } from "../commonCategories/CategoriesRow";
import { useDispatch, useSelector } from "react-redux";
import {
  standardCategoriesListSelector,
  unspcFamilyListSelector,
} from "../../data/selectors/user";
import {
  getStandardCategoriesInfo,
  getUNSPCClassInfo,
} from "../../data/actions/user";
import {
  PlusSignArea,
  SmallAddText,
  StyledAddText,
  StyledCategoriesArea,
} from "../commonCategories/styles";
import PlusSign from "../../assets/onBoarding/plusSign";
import { StyledSubtext } from "../../pages/userOnBoardingPage/components/styles";
import RadioGroupButtonForProjectCategory from "../radioGroupButtonForProjectCategory";
import { AddressAutoFields } from "../addClientPopup/AddressAutoFields";
import { addNewAccountForUnderwriter } from "../../api/external_data";

export const AddAccountPopup = ({ open, setOpen }) => {
  console.log("open ", open);
  const [form, setForm] = useState({
    accountId: "",
    companyName: "",
    submissionDate: "",
    accountType: "",
    address: "",
    uwOffice: "",
    contractorType: "",
    contractorGroup: "",
    financialYeDate: "",
    naicsCode: "",
  });

  const [fullAddress, setFullAddress] = useState({
    address: "",
    suite: "",
    postal: "",
    city: "",
    province: "",
    country: "",
  });

  const [step, setStep] = useState(0);
  const [error, setError] = useState("");
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [categories, setCategories] = useState([]);

  const titles = [
    "Add A New Account",
    "Enter Account Details",
    "Invite A New Principle To Onboard",
  ];

  const standardCategoriesList = useSelector(standardCategoriesListSelector);
  const unspcFamilyList = useSelector(unspcFamilyListSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (standardCategoriesList?.length < 1) {
      dispatch(getStandardCategoriesInfo());
    }
  }, []);

  useEffect(() => {
    if (unspcFamilyList?.length < 1) {
      dispatch(getUNSPCClassInfo());
    }
  }, []);

  useEffect(() => {
    if (!open) {
      setStep(0);
      setForm({
        accountId: "",
        companyName: "",
        submissionDate: "",
        accountType: "",
        address: "",
        uwOffice: "",
        contractorType: "",
        contractorGroup: "",
        financialYeDate: "",
        naicsCode: "",
      });
    }
  }, [open]);

  const handleInputChange = (field, value) => {
    setForm((prevForm) => ({
      ...prevForm,
      [field]: value,
    }));
  };

  const handleSubmit = async (form) => {
    console.log("form ", form);
    console.log("cat ", categories);
    console.log("address", fullAddress);

    const createCategories = () => {
      const filteredCategories = categories.filter((category) => {
        if (
          category[0].main_categories !== "" &&
          category[0].sub_categories &&
          category[0].sub_categories?.length > 0
        ) {
          return category[0];
        }
      });

      const categoryGroups = [
        ...new Set(
          filteredCategories.map((category) => category[0].main_categories),
        ),
      ];

      const categoryTypes = filteredCategories.flatMap((category) => {
        return category[0].sub_categories.map((sub, index) => {
          return {
            [`Sub-Category-${index + 1}`]: sub,
          };
        });
      });

      // Stringify both categoryGroups and categoryTypes
      const categoryGroupsString = JSON.stringify(categoryGroups);
      const categoryTypesString = JSON.stringify(categoryTypes);

      return { categoryGroupsString, categoryTypesString };
    };

    const { categoryGroupsString, categoryTypesString } = createCategories();

    console.log("full address: ", fullAddress);

    try {
      await addNewAccountForUnderwriter(
        {
          ...form,
          accountId: parseInt(form.accountId),
          contractorGroup: categoryGroupsString,
          contractorType: categoryTypesString,
          address:
            fullAddress.address +
            " " +
            fullAddress.suite +
            " " +
            fullAddress.postal +
            " " +
            fullAddress.city +
            " " +
            fullAddress.province +
            " " +
            fullAddress.country,
        },
        () => {
          setOpen(false);
          setShowSuccessDialog(true);
          console.log("Successfully added the new underwriter!");
        },
        ({ data }) => {
          console.warn("Failed to add the new underwriter: ", data.error);
          setError(
            data.error ||
              "Unknown error, please contact PledgX support for assistance.",
          );
          setShowErrorDialog(true);
        },
      );
    } catch (e) {
      console.log("Exception in addNewAccountForUnderwriter: ", e);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        setOpen={setOpen}
        aria-labelledby="add-new-account-dialog"
        aria-describedby="diaog to add new account"
        maxWidth="md"
        TransitionComponent={React.Fragment}
      >
        <StyledPaper elevation={0}>
          <PledgxLogo />
          <FormTitle style={{ padding: "18px" }}>{titles[step]}</FormTitle>
          <CloseButton onClick={() => setOpen(false)} aria-label="close">
            <CloseIcon />
          </CloseButton>
          <div
            style={{
              overflowY: "auto",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {step === 0 && (
              <div
                style={{
                  padding: "20px 0",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ marginBottom: "16px" }}>
                  <FunctionalButton
                    buttonTitle="Add Manually"
                    handleButton={() => setStep(1)}
                  />
                </div>
                <div style={{ marginBottom: "16px" }}>
                  <FunctionalButton
                    buttonTitle="Invite To Onboard"
                    handleButton={() => setStep(2)}
                  />
                </div>
                <FunctionalButton
                  buttonTitle="Claim In PledgX"
                  handleButton={() => setStep(3)}
                />
              </div>
            )}
            {step === 1 && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    gap: "16px",
                  }}
                >
                  <Input
                    id="fullWidthInputWrap"
                    label="Account Name"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    validateFunc={(item) => Boolean(item)}
                    setValue={(e) => handleInputChange("companyName", e)}
                    value={form.companyName}
                  />
                </div>

                <AddressAutoFields
                  data={form.address}
                  setData={(e, val) => {
                    console.log("e: ", e);
                    console.log("val: ", val);
                    setFullAddress((address) => {
                      if (e === "company") {
                        return {
                          city: val.city,
                          address: val.address,
                          suite: val.suite,
                          postal: val.postal,
                          country: val.country,
                          province: val.province,
                        };
                      }

                      return {
                        ...address,
                        [e.split(".").slice(1).join(".")]: val,
                      };
                    });
                  }}
                  hasMargin
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    gap: "16px",
                  }}
                >
                  <Input
                    id="fullWidthInputWrap"
                    label="Account ID"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    validateFunc={(item) => Boolean(item)}
                    setValue={(e) => handleInputChange("accountId", e)}
                    value={form.accountId}
                  />
                  <Select
                    label="Account Type"
                    options={(() =>
                      ["Contract", "Commercial", "Other"].map((type) => ({
                        value: type,
                        label: type,
                      })))()}
                    value={form.accountType}
                    setValue={(e) => handleInputChange("accountType", e)}
                    noCheckIcon={true}
                    showDropdownIndicator={true}
                    validateFunc={(item) => Boolean(item)}
                  />
                  <Input
                    id="fullWidthInputWrap"
                    label="Underwriter Office"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    validateFunc={(item) => item.length > 0}
                    setValue={(e) => handleInputChange("uwOffice", e)}
                    value={form.uwOffice}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    gap: "16px",
                  }}
                >
                  <DateTimePickerField
                    showDateField={true}
                    dateWidth="100%"
                    dateLabel="Submission Date"
                    backgroundColor="rgba(235, 233, 241, 0.75)"
                    dateValue={form.submissionDate}
                    setDateValue={(e) => handleInputChange("submissionDate", e)}
                  />
                  <DateTimePickerField
                    showDateField={true}
                    dateWidth="100%"
                    dateLabel="Financial YE Date"
                    backgroundColor="rgba(235, 233, 241, 0.75)"
                    dateValue={form.financialYeDate}
                    setDateValue={(e) =>
                      handleInputChange("financialYeDate", e)
                    }
                  />
                  <Select
                    label="NAICS Code"
                    options={(() =>
                      naicsCodes.map((code) => ({
                        value: code,
                        label: code,
                      })))()}
                    value={form.naicsCode}
                    setValue={(e) => handleInputChange("naicsCode", e)}
                    noCheckIcon={true}
                    showDropdownIndicator={true}
                    validateFunc={(item) => Boolean(item)}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <CategorySelect
                    data={form.contractorType}
                    setData={(attribute, val) => {
                      setCategories((old) => [...old, val]);
                    }}
                    notPopup={true}
                    categoriesStandardData={standardCategoriesList}
                    categoriesUNSPCData={unspcFamilyList}
                    containsUNSPC={true}
                  />
                </div>
                <div
                  style={{
                    margin: "40px 0",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <FunctionalButton
                    buttonTitle="Confirm"
                    handleButton={() => handleSubmit(form)}
                    // disabled={
                    //   !form.accountId ||
                    //   !form.companyName ||
                    //   !form.submissionDate ||
                    //   !form.accountType ||
                    //   !form.address ||
                    //   !form.uwOffice ||
                    //   !form.financialYeDate ||
                    //   !form.naicsCode
                    // }
                  />
                </div>
              </>
            )}
          </div>
        </StyledPaper>
      </Dialog>
      {/* Success Dialog */}
      <Dialog
        open={showSuccessDialog}
        onClose={() => setShowSuccessDialog(false)}
        aria-labelledby="success-dialog"
        aria-describedby="success-dialog"
        maxWidth="md"
        TransitionComponent={React.Fragment}
      >
        <StyledPaper elevation={0}>
          <CloseButton
            aria-label="close"
            onClick={() => setShowSuccessDialog(false)}
          >
            <CloseIcon />
          </CloseButton>
          <Success />
          <FormTitle
            style={{
              marginTop: "10px",
              color: "#1A051D",
              fontSize: "22px",
              fontWeight: "600",
              fontStyle: "normal",
              lineHeight: "24px",
            }}
          >
            Success
          </FormTitle>
          <SmallTitleText
            style={{
              marginBottom: 10,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            The account has been successfully created.
          </SmallTitleText>
          <FunctionalButton
            buttonTitle="Close"
            handleButton={() => setShowSuccessDialog(false)}
            width="308px"
            height="48px"
            isReversedColor={false}
            marginRight={"48px"}
            marginLeft={"48px"}
            marginTop={"30px"}
            marginBottom={"20px"}
            fontSize={"18px"}
            disabled={false}
            icon={null}
            hasBoxShadow={true}
            frontIcon={null}
            weight={600}
          />
        </StyledPaper>
      </Dialog>

      {/* Error Dialog */}
      <AlertPopup
        headerText="Account Creation Unsuccessful"
        height="434px"
        subHeaderText={error}
        openPopup={showErrorDialog}
        setOpenPopup={setShowErrorDialog}
        showIcon={true}
        width={"640px"}
        showButton={false}
      />
    </>
  );
};

const AddressInput = ({ value, setValue }) => {
  const inputRef = useRef(null);

  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyCwLidsAgErglXnD9dT6qnaQF7u_DYU3Pc&libraries=places`,
      () => {},
    );
  });

  useEffect(() => {
    // Ensure Google Maps API is available
    if (!window.google || !window.google.maps) {
      console.error("Google Maps JavaScript API is not loaded.");
      return;
    }
    // Initialize the Google Places Autocomplete
    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current,
      {
        types: ["geocode"], // Suggest addresses only
        componentRestrictions: { country: "us" }, // Restrict to specific country (optional)
      },
    );
    // Add an event listener to get the selected address
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      setValue(place.formatted_address);
      console.log("Selected Place:", place);
    });
    // Cleanup listener on unmount
    return () => window.google.maps.event.clearInstanceListeners(autocomplete);
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
        width: "100%",
      }}
    >
      <InputLabel>Address</InputLabel>
      <input
        type="text"
        ref={inputRef}
        style={{
          width: "100%",
          padding: "1px 40px 1px 15px",
          fontSize: "16px",
          border: "1px solid #ccc",
          borderRadius: "4px",
          height: "48px",
        }}
      />
    </div>
  );
};

const CategorySelect = ({
  data,
  setData,
  attribute,
  usePlusIcon,
  marginTopSecondary,
  isMobile,
  width,
  notPopup,
  readOnly,
  categoriesUNSPCData,
  categoriesStandardData,
  initialSubCategory,
}) => {
  const [enableAdd, setEnableAdd] = useState(true);

  const [containsUNSPC, setContainsUNSPC] = useState(false);

  const onRadioChange = (val) => {
    if (val === 1) {
      setContainsUNSPC(false);
    } else {
      setContainsUNSPC(true);
    }
  };

  const addInitialList = (data) => {
    const tmp = [];
    if (data?.categories?.length > 0) {
      const list = data?.categories;
      const listOfMain = list.map((item) => item.main_categories);
      list.map((item) => {
        tmp.push(
          <CategoriesRow
            attribute={attribute}
            setEnableAdd={setEnableAdd}
            data={data}
            setData={setData}
            categoriesUNSPC={categoriesUNSPCData}
            categoriesStandard={categoriesStandardData}
            initialSubCategory={initialSubCategory}
            containsUNSPC={containsUNSPC}
            onRadioChange={onRadioChange}
            marginTop="0px"
            index={item.index}
            categories={item}
            mainSelected={listOfMain}
            isMobile={isMobile}
            marginTopSecondary={marginTopSecondary}
            readOnly={readOnly}
          />,
        );
      });
    } else {
      tmp.push(
        <CategoriesRow
          attribute={attribute}
          setEnableAdd={setEnableAdd}
          data={data}
          setData={setData}
          categoriesUNSPC={categoriesUNSPCData}
          categoriesStandard={categoriesStandardData}
          initialSubCategory={initialSubCategory}
          containsUNSPC={containsUNSPC}
          onRadioChange={onRadioChange}
          index={0}
          isMobile={isMobile}
          marginTopSecondary={marginTopSecondary}
          readOnly={readOnly}
        />,
      );
    }
    return tmp;
  };

  const [addList, setAddList] = useState([]);

  useEffect(() => {
    setAddList([]);
    addInitialList([]);
  }, [containsUNSPC]);

  useEffect(() => {
    if (notPopup) {
      setAddList([]);
    }
  }, [data?.categories]);

  useEffect(() => {
    if (addList?.length == 0 && notPopup) {
      setAddList(addInitialList(data));
    }
  }, [addList]);

  useEffect(() => {
    if (!notPopup) {
      setAddList(addInitialList(data));
    }
  }, [data]);

  const onAdd = () => {
    const index = addList.length;
    const list = data?.categories;
    const listOfMain =
      list?.length > 0 ? list.map((item) => item.main_categories) : [];
    setAddList(
      addList.concat(
        <CategoriesRow
          data={data}
          setData={setData}
          categoriesUNSPC={categoriesUNSPCData}
          categoriesStandard={categoriesStandardData}
          initialSubCategory={initialSubCategory}
          containsUNSPC={containsUNSPC}
          onRadioChange={onRadioChange}
          index={index}
          marginTop="0px"
          marginTopSecondary={marginTopSecondary}
          mainSelected={listOfMain}
          isMobile={isMobile}
          attribute={attribute}
          setEnableAdd={setEnableAdd}
          readOnly={readOnly}
        />,
      ),
    );
  };

  return (
    <>
      <StyledSubtext isMobile={isMobile} width="100%">
        <RadioGroupButtonForProjectCategory
          containsUNSPC={containsUNSPC}
          confirmRadioChange={onRadioChange}
        />
      </StyledSubtext>
      <StyledCategoriesArea isMobile={isMobile} width={width}>
        {addList}
        <PlusSignArea usePlusIcon={usePlusIcon}>
          {usePlusIcon && (
            <>
              <PlusSign enableAdd={enableAdd} onAdd={onAdd} />
              <StyledAddText
                onClick={enableAdd ? onAdd : () => {}}
                enableAdd={enableAdd}
              >
                Add Category
              </StyledAddText>
            </>
          )}
          {!usePlusIcon && (
            <SmallAddText
              onClick={enableAdd ? onAdd : () => {}}
              enableAdd={enableAdd}
            >
              + Add Category
            </SmallAddText>
          )}
        </PlusSignArea>
      </StyledCategoriesArea>
    </>
  );
};
