import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { StyledButton, StyledMenu, StyledMenuItem } from "pages/underwriters/components/allClientsTable/styles.js";
import { generatePGA } from "../../../../api/external_data";
import VerticalThreeDots from "assets/dashboardIcons/verticalThreeDots";
import { useHistory } from "react-router";
import { SuccessPopup } from "./SuccessPopup";
import { AlertMessage } from "./alertMessage";
import { LoadingMessage } from "./loadingMessage";

export const ActionsMenuForPGA = ({ client_id, companyName, status, setData, viewPeerGroupAnalysis }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [notSuccessPopup, setNotSuccessPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const viewPeerGroupAnalysisClientData = () => {
    history.push("/underwriter/client/peergroupanalysis", {
      company_name: companyName,
      account_id: client_id
    });
  };

  const generatePeerGroupAnalysisClientData = async () => {
    setLoading(true);
    const data = {
      company_name: companyName,
      account_id: client_id
    };

    try {
      await generatePGA(data, onSuccessPGAData, onFailurPGAData);
    } catch (error) {
      setNotSuccessPopup(true);
    } finally {
      setLoading(false);
    }
  };

  const onSuccessPGAData = (res) => {
    setOpenSuccessPopup(true);
  };

  const onFailurPGAData = (res) => {
    setNotSuccessPopup(true);
  };

  if (["deleted"].includes(status)) {
    return <></>;
  } else {
    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{ padding: 0 }}
        >
          <VerticalThreeDots />
        </IconButton>
        <StyledMenu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          <StyledMenuItem>
            <StyledButton width="180px" onClick={viewPeerGroupAnalysisClientData}>
              View Last PGA
            </StyledButton>
          </StyledMenuItem>

          <StyledMenuItem>
            <StyledButton
              width="180px"
              onClick={generatePeerGroupAnalysisClientData}
              disabled={loading} // Disable button when loading
            >
              Update PGA and Scores
            </StyledButton>
          </StyledMenuItem>
        </StyledMenu>

        <SuccessPopup
          setOpenPopup={setOpenSuccessPopup}
          openPopup={openSuccessPopup}
        />

        <AlertMessage
          openPopup={notSuccessPopup}
          setOpenPopup={setNotSuccessPopup}
          description="Sorry 😟, I couldn't find sufficient data in this account to generate any insights."
          isFormatIssue={false}
        />

        <LoadingMessage
          openPopup={loading}
          setOpenPopup={setLoading}
          description="Working on it ⏳"
          isFormatIssue={false}
        />

      </>
    );
  }
};
