import styled from "styled-components";
import { Container } from "../../components/common";
import FormControlLabel from "@mui/material/FormControlLabel";

export const StyledContainer = styled(Container)`
  && {
    padding-bottom: 100px;
    margin: 0 ${(props) => (props.isMobile ? "0px" : "70px")};
    width: 100%;
  }
`;

// submission deatil page

export const sectionTitle = styled.div``;

export const StyledSectionHeader = styled.div`
  font-family: SF Pro Text;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`;

export const StyledFinancialContainer = styled.div``;
export const StyledWhiteContainer = styled.div`
  background: #ffffff;
  height: 456px;
  width: 1080px;
`;

export const StyledRadioLabel = styled(FormControlLabel)`
  && {
    font-size: 28px;
    font-weight: bold;
    margin-top: 16px;
    margin-left: 0px;
    margin-right: 14px;

    .MuiTypography-body1 {
      font-size: 12px;
      font-weight: 500;
    }

    .MuiIconButton-label {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
`;
