import React from "react";

import { StyledCellTitle, StyledCellCommon, StyledCellAction } from "./styles";
import { ActionsMenuForPGA } from "pages/underwriters/components/peerGroupAnalysis/ActionsMenuForPGA.js"
import { ActionsMenu } from "./ActionsMenu";
import ConciseTable from "components/filesListTable/conciseTable";

export const AllClientsTable = ({
  data,
  setData,
  isMobile,
  borderRadius,
  boxShadow,
  isShortened,
  isPGA,
}) => {
  const body = (bodyData) => {
    return (
      <>
        <StyledCellTitle component={"tr"} borderRadius={borderRadius}>
          {bodyData?.AccountId}
        </StyledCellTitle>
        {!isShortened && (
          <StyledCellCommon
            component={"tr"}
            scope="row"
            borderRadius={borderRadius}
          >
            {bodyData?.CompanyName}
          </StyledCellCommon>
        )}
        <StyledCellCommon
          component={"tr"}
          scope="row"
          borderRadius={borderRadius}
        >
           {bodyData.Province !== null && bodyData.Province !== undefined && bodyData.Province !== "NULL" ? bodyData.Province : ""}
        </StyledCellCommon>
        <StyledCellCommon
          component={"tr"}
          scope="row"
          borderRadius={borderRadius}
        >
          {bodyData?.facount}
        </StyledCellCommon>
        <StyledCellCommon
          component={"tr"}
          scope="row"
          borderRadius={borderRadius}
        >
          {bodyData?.insightAvailable}
        </StyledCellCommon>
      </>
    );
  };

  const actions = (props) => {
    return (
      <>
        <StyledCellAction
          component={"tr"}
          scope="row"
          align="center"
          borderRadius={borderRadius}
        >
          {isPGA === true ? (
            <ActionsMenuForPGA
              client_id={props.AccountId}
              companyName={props.CompanyName}
              setData={setData}
            />
          ) : (
            <ActionsMenu
              client_id={props.AccountId}
              companyName={props.CompanyName}
              setData={setData}
            />
          )}
        </StyledCellAction>
      </>
    );
  };

  const createHeader = (title, width, align) => {
    return {
      title,
      width: width || "",
      align: align || "left",
    };
  };

  const headers = [
    createHeader("Account ID", "20%"),
    createHeader("Company Name", "25%"),
    createHeader("State/Province", "15%"),
    createHeader("# of Financial Statements", "25%"),
    createHeader("Insight Available", "15%"),
    createHeader(""),
  ];
  const shortenedHeaders = [
    createHeader("Account ID"),
    createHeader("Company Name"),
    createHeader(""),
    createHeader(""),
  ];

  return (
    <div>
      <ConciseTable
        headers={isMobile ? "" : isShortened ? shortenedHeaders : headers}
      />
      <ConciseTable
        actions={actions}
        actionType="client"
        data={data}
        //height="1200px"
        body={body}
        boxShadow={boxShadow}
        borderRadius={borderRadius}
      // headers={isMobile ? "" : isShortened ? shortenedHeaders : headers}
      />
    </div>
  );
};
